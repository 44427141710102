<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="branch_id" label="支店" prop="branch_id">
        <a-select show-search v-model="form.branch_id" :filter-option="filterOption">
          <a-select-option v-for="item in branches" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="name" label="車両名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="number" label="車両番号" prop="number">
        <a-input
          v-model="form.number"
          @blur="
            () => {
              $refs.number.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item v-if="myRole <= 1 && myCompanyPlan === 4" ref="logger_id" label="ロガー番号" prop="logger_id">
        <a-input-number
          :min="0"
          v-model="form.logger_id"
          style="margin-right: 5px;"
          @blur="
            () => {
              $refs.logger_id.onFieldBlur()
            }
          "
        />
        0 を入力すると自動入力になります。
      </a-form-model-item>
      <a-form-model-item label="車種" prop="car_type" ref="car_type">
        <a-select v-model="form.car_type" placeholder="車種を選択してください。">
          <a-select-option v-for="item in carTypes" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item ref="manufacturer" label="メーカー" prop="manufacturer">
        <a-input
          v-model="form.manufacturer"
          @blur="
            () => {
              $refs.manufacturer.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-form-model-item ref="model" label="型式" prop="model">
        <a-input
          v-model="form.model"
          @blur="
            () => {
              $refs.model.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-config-provider :locale="locale">
        <a-form-model-item ref="legal_inspection_date" label="次回法定点検日" prop="legal_inspection_date">
          <a-date-picker v-model="legalInspectionDate" @change="changeLegalInspectionDate" />
        </a-form-model-item>
      </a-config-provider>

      <a-config-provider :locale="locale">
        <a-form-model-item ref="inspection_expiration_date" label="車検満了日" prop="inspection_expiration_date">
          <a-date-picker v-model="inspectionExpirationDate" @change="changeInspectionExpirationDate" />
        </a-form-model-item>
      </a-config-provider>

      <a-form-model-item ref="ownership" label="所有形態" prop="ownership">
        <a-radio-group v-model="form.ownership">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">リース</a-radio>
          <a-radio :value="2">新車</a-radio>
          <a-radio :value="3">中古</a-radio>
          <a-radio :value="4">その他</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="monthly_lease_fee" label="月額リース料" prop="monthly_lease_fee">
        <a-input-number
          :min="0"
          v-model="form.monthly_lease_fee"
          style="margin-right: 5px;"
          @blur="
            () => {
              $refs.monthly_lease_fee.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-config-provider :locale="locale">
        <a-form-model-item ref="lease_expiration_date" label="リース満了日" prop="lease_expiration_date">
          <a-date-picker v-model="leaseExpirationDate" @change="changeLeaseExpirationDate" />
        </a-form-model-item>
      </a-config-provider>

      <a-config-provider :locale="locale">
        <a-form-model-item ref="date_purchase" label="購入年月日" prop="date_purchase">
          <a-date-picker v-model="datePurchase" @change="changeDatePurchase" />
        </a-form-model-item>
      </a-config-provider>

      <a-config-provider :locale="locale">
        <a-form-model-item ref="insurance_expiration_date" label="任意保険満了日" prop="insurance_expiration_date">
          <a-date-picker v-model="insuranceExpirationDate" @change="changeInsuranceExpirationDate" />
        </a-form-model-item>
      </a-config-provider>

      <a-form-model-item ref="drive_recorder" label="ドライブレコーダー設置有無" prop="drive_recorder">
        <a-radio-group v-model="form.drive_recorder">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">有り</a-radio>
          <a-radio :value="2">無し</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="only_app" label="アプリのみでの使用" prop="only_app">
        <a-radio-group v-model="form.only_app">
          <a-radio :value="1">アプリのみ</a-radio>
          <a-radio :value="0">アプリ以外でも使用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="is_archived" label="無効化" prop="is_archived">
        <a-radio-group v-model="form.is_archived">
          <a-radio :value="0">無効化にしない</a-radio>
          <a-radio :value="1">無効化にする</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="備考" ref="description">
        <a-input v-model="form.description" type="textarea" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">
          {{ submitString }}
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>

import Vue from 'vue'
import moment from 'moment'
import { objectData } from '@/services/object'
import { enumData } from '@/services/enum'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  name: 'FormVehicle',
  data() {
    return {
      locale: jaJa,
      loading: true,
      submitType: this.$route.meta.submitType,
      submitString: this.$route.meta.submitString,
      backPath: '/',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      vehicle: Object.assign({}, objectData.vehicle),
      branches: [], // 支店の選択項目リスト
      form: {
        branch_id: null,
        name: '',
        number: '',
        logger_id: 0,
        car_type: '',
        only_app: 0,
        manufacturer: '',
        model: '',
        inspection_expiration_date: null,
        ownership: 0,
        monthly_lease_fee: null,
        date_purchase: null,
        insurance_expiration_date: null,
        drive_recorder: 0,
        lease_expiration_date: null,
        legal_inspection_date: null,
        is_archived: 0,
        description: '',
      },
      inspectionExpirationDate: null,
      leaseExpirationDate: null,
      legalInspectionDate: null,
      datePurchase: null,
      insuranceExpirationDate: null,
      carTypes: enumData.carType,
      rules: {
        branch_id: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        logger_id: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        car_type: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        only_app: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        is_archived: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
      myRole: 3,
      myCompanyPlan: 3,
    }
  },
  watch: {
    vehicle: function(val) {
      this.form.branch_id = val.branch_id
      this.form.name = val.name
      this.form.number = val.number
      this.form.logger_id = val.logger_id
      this.form.car_type = val.car_type
      this.form.only_app = val.only_app
      this.form.manufacturer = val.manufacturer
      this.form.model = val.model
      this.form.inspection_expiration_date = val.inspection_expiration_date
      this.form.ownership = val.ownership
      this.form.monthly_lease_fee = val.monthly_lease_fee
      this.form.date_purchase = val.date_purchase
      this.form.drive_recorder = val.drive_recorder
      this.form.lease_expiration_date = val.lease_expiration_date
      this.form.legal_inspection_date = val.legal_inspection_date
      this.form.insurance_expiration_date = val.insurance_expiration_date
      this.form.is_archived = val.is_archived
      this.form.description = val.description
      if (val.inspection_expiration_date) this.inspectionExpirationDate = moment(val.inspection_expiration_date, 'YYYY-MM-DD')
      if (val.date_purchase) this.datePurchase = moment(val.date_purchase, 'YYYY-MM-DD')
      if (val.lease_expiration_date) this.leaseExpirationDate = moment(val.lease_expiration_date, 'YYYY-MM-DD')
      if (val.legal_inspection_date) this.legalInspectionDate = moment(val.legal_inspection_date, 'YYYY-MM-DD')
      if (val.insurance_expiration_date) this.insuranceExpirationDate = moment(val.insurance_expiration_date, 'YYYY-MM-DD')
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'vehicles/' + _this.$route.params.branch_id, this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'vehicles/' + _this.$route.params.id, this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push('/vehicles/' + _this.$route.params.id)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    changeInspectionExpirationDate(date) {
      if (date) this.form.inspection_expiration_date = date.format('YYYY-MM-DD')
      else this.form.inspection_expiration_date = null
    },
    changeDatePurchase(date) {
      if (date) this.form.date_purchase = date.format('YYYY-MM-DD')
      else this.form.date_purchase = null
    },
    changeInsuranceExpirationDate(date) {
      if (date) this.form.insurance_expiration_date = date.format('YYYY-MM-DD')
      else this.form.insurance_expiration_date = null
    },
    changeLeaseExpirationDate(date) {
      if (date) this.form.lease_expiration_date = date.format('YYYY-MM-DD')
      else this.form.lease_expiration_date = null
    },
    changeLegalInspectionDate(date) {
      if (date) this.form.legal_inspection_date = date.format('YYYY-MM-DD')
      else this.form.legal_inspection_date = null
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
  created() {
    const _this = this
    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      _this.myRole = response.role
      _this.myCompanyPlan = response.company_plan
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })

    const branchList = Vue.prototype.$api.send('get', 'branches')
    branchList.then(response => {
      this.branches = response
      if (this.submitType === 'post') {
        this.form.branch_id = Number(this.$route.params.branch_id)
      }
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    if (this.submitType === 'post') {
      this.loading = false
      this.backPath = '/branches/' + this.$route.params.branch_id
    } else if (this.submitType === 'put') {
      this.backPath = '/vehicles/' + this.$route.params.id
      const result = Vue.prototype.$api.send('get', 'vehicles/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.vehicle = response
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
          this.loading = false
        })
    }
  },
}
</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
