var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "branch_id",
                  attrs: { label: "支店", prop: "branch_id" }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        "filter-option": _vm.filterOption
                      },
                      model: {
                        value: _vm.form.branch_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "branch_id", $$v)
                        },
                        expression: "form.branch_id"
                      }
                    },
                    _vm._l(_vm.branches, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "車両名", prop: "name" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.name.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "number", attrs: { label: "車両番号", prop: "number" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.number.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  })
                ],
                1
              ),
              _vm.myRole <= 1 && _vm.myCompanyPlan === 4
                ? _c(
                    "a-form-model-item",
                    {
                      ref: "logger_id",
                      attrs: { label: "ロガー番号", prop: "logger_id" }
                    },
                    [
                      _c("a-input-number", {
                        staticStyle: { "margin-right": "5px" },
                        attrs: { min: 0 },
                        on: {
                          blur: function() {
                            _vm.$refs.logger_id.onFieldBlur()
                          }
                        },
                        model: {
                          value: _vm.form.logger_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "logger_id", $$v)
                          },
                          expression: "form.logger_id"
                        }
                      }),
                      _vm._v("\n      0 を入力すると自動入力になります。\n    ")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { ref: "car_type", attrs: { label: "車種", prop: "car_type" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "車種を選択してください。" },
                      model: {
                        value: _vm.form.car_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "car_type", $$v)
                        },
                        expression: "form.car_type"
                      }
                    },
                    _vm._l(_vm.carTypes, function(item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n          " + _vm._s(item.label) + "\n        "
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "manufacturer",
                  attrs: { label: "メーカー", prop: "manufacturer" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.manufacturer.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.manufacturer,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "manufacturer", $$v)
                      },
                      expression: "form.manufacturer"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "model", attrs: { label: "型式", prop: "model" } },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.model.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.model,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "model", $$v)
                      },
                      expression: "form.model"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-config-provider",
                { attrs: { locale: _vm.locale } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "legal_inspection_date",
                      attrs: {
                        label: "次回法定点検日",
                        prop: "legal_inspection_date"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        on: { change: _vm.changeLegalInspectionDate },
                        model: {
                          value: _vm.legalInspectionDate,
                          callback: function($$v) {
                            _vm.legalInspectionDate = $$v
                          },
                          expression: "legalInspectionDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-config-provider",
                { attrs: { locale: _vm.locale } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "inspection_expiration_date",
                      attrs: {
                        label: "車検満了日",
                        prop: "inspection_expiration_date"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        on: { change: _vm.changeInspectionExpirationDate },
                        model: {
                          value: _vm.inspectionExpirationDate,
                          callback: function($$v) {
                            _vm.inspectionExpirationDate = $$v
                          },
                          expression: "inspectionExpirationDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "ownership",
                  attrs: { label: "所有形態", prop: "ownership" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.ownership,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ownership", $$v)
                        },
                        expression: "form.ownership"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("リース")
                      ]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("新車")]),
                      _c("a-radio", { attrs: { value: 3 } }, [_vm._v("中古")]),
                      _c("a-radio", { attrs: { value: 4 } }, [_vm._v("その他")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "monthly_lease_fee",
                  attrs: { label: "月額リース料", prop: "monthly_lease_fee" }
                },
                [
                  _c("a-input-number", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { min: 0 },
                    on: {
                      blur: function() {
                        _vm.$refs.monthly_lease_fee.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.monthly_lease_fee,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "monthly_lease_fee", $$v)
                      },
                      expression: "form.monthly_lease_fee"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-config-provider",
                { attrs: { locale: _vm.locale } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "lease_expiration_date",
                      attrs: {
                        label: "リース満了日",
                        prop: "lease_expiration_date"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        on: { change: _vm.changeLeaseExpirationDate },
                        model: {
                          value: _vm.leaseExpirationDate,
                          callback: function($$v) {
                            _vm.leaseExpirationDate = $$v
                          },
                          expression: "leaseExpirationDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-config-provider",
                { attrs: { locale: _vm.locale } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "date_purchase",
                      attrs: { label: "購入年月日", prop: "date_purchase" }
                    },
                    [
                      _c("a-date-picker", {
                        on: { change: _vm.changeDatePurchase },
                        model: {
                          value: _vm.datePurchase,
                          callback: function($$v) {
                            _vm.datePurchase = $$v
                          },
                          expression: "datePurchase"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-config-provider",
                { attrs: { locale: _vm.locale } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "insurance_expiration_date",
                      attrs: {
                        label: "任意保険満了日",
                        prop: "insurance_expiration_date"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        on: { change: _vm.changeInsuranceExpirationDate },
                        model: {
                          value: _vm.insuranceExpirationDate,
                          callback: function($$v) {
                            _vm.insuranceExpirationDate = $$v
                          },
                          expression: "insuranceExpirationDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "drive_recorder",
                  attrs: {
                    label: "ドライブレコーダー設置有無",
                    prop: "drive_recorder"
                  }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.drive_recorder,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "drive_recorder", $$v)
                        },
                        expression: "form.drive_recorder"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未選択")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("有り")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無し")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "only_app",
                  attrs: { label: "アプリのみでの使用", prop: "only_app" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.only_app,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "only_app", $$v)
                        },
                        expression: "form.only_app"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("アプリのみ")
                      ]),
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("アプリ以外でも使用")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "is_archived",
                  attrs: { label: "無効化", prop: "is_archived" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.is_archived,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_archived", $$v)
                        },
                        expression: "form.is_archived"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("無効化にしない")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("無効化にする")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "description", attrs: { label: "備考" } },
                [
                  _c("a-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    {
                      class: _vm.$style.filledBtn,
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: _vm.backPath } },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }